import { ElementType, lazy, Suspense } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';

// layouts
// components
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([{ path: '/', element: <Table /> }]);
}

const Table = Loadable(lazy(() => import('../pages/Table')));
